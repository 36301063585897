import styled from "styled-components"

import { MemberInvites } from "src/components/Organizations/Organization/MemberInvites"
import { MemberTable } from "src/components/Organizations/Organization/MemberTable"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchMemberInvites } from "src/data/organizations/queries/inviteQueries"
import { useFetchMembers } from "src/data/organizations/queries/memberQueries"
import { InvitationState } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { MainView } from "src/ui/Layout/MainView"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function Members() {
  const { orgAccessLogic, orgId } = useOrganization()
  const { navigate } = useRouter()

  const { limit, offset, setOffset } = useUrlPager()
  const { t, langKeys } = useTranslate()
  const isOrgAdmin = orgAccessLogic.hasAdminAccess

  const fetchMembers = useFetchMembers({
    orgId,
    filter: { limit, offset },
    options: { keepPreviousData: true },
  })
  const fetchMemberInvites = useFetchMemberInvites({
    orgId,
    filter: {
      invitation_state: InvitationState.PENDING,
      limit,
      offset,
    },
    options: { enabled: orgAccessLogic.hasMemberAccess },
  })

  function handleAddMember() {
    navigate(Routes.OrgMemberAdd.location(orgId))
  }

  if (fetchMemberInvites.error || fetchMembers.error) {
    return (
      <MBanner type="error">{t(langKeys.failed_general_error_title)}</MBanner>
    )
  }

  return (
    <MainView
      title={t(langKeys.team)}
      titleBarProps={{
        actionBar: isOrgAdmin && (
          <MButton onClick={handleAddMember}>
            {t(langKeys.organizations_add_member)}
          </MButton>
        ),
      }}
    >
      <Box>
        <TeamBox>
          <div style={{ opacity: fetchMemberInvites.isFetching ? 0.5 : 1 }}>
            <MemberTable
              orgId={orgId}
              members={fetchMembers.data?.members}
              loading={fetchMembers.isLoading}
            />
            <PagerWrapper>
              <Pager
                limit={limit ?? HOME_LIST_LIMIT}
                offset={offset ?? 0}
                setOffset={setOffset}
                totalCount={fetchMembers.data?.paging.total_count || 0}
              />
            </PagerWrapper>
          </div>

          {!!fetchMemberInvites.data?.invitations?.length && (
            <div style={{ opacity: fetchMemberInvites.isFetching ? 0.5 : 1 }}>
              <MText variant="heading3">
                {t(langKeys.organization_invite_plural)}
              </MText>
              <MemberInvites
                orgId={orgId}
                invites={fetchMemberInvites.data.invitations || []}
                adminAccess={orgAccessLogic.hasAdminAccess}
              />
            </div>
          )}
        </TeamBox>
      </Box>
    </MainView>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.XL};
  margin-top: ${spacing.XL};
`

const TeamBox = styled.div`
  display: grid;
  grid-gap: ${spacing.XL4};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`
